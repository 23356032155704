import { useEffect } from "react";

import LoadingPage from "~src/common/components/loadingPage";
import type { NextPage } from "~types/next";
import { voidFunction } from "~utils/functions";
import { Paths } from "~utils/paths";

import { useRouter } from "next/router";

const IndexPage: NextPage = () => {
  const { replace } = useRouter();
  useEffect(() => {
    replace(Paths.Login).catch(voidFunction);
  }, []);

  return <LoadingPage />;
};

export default IndexPage;
